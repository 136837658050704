const Flickity = require("flickity");

document.addEventListener("DOMContentLoaded", function (event) {
  preCheck();
});

let isMobile;
function preCheck() {
  isMobile = window.innerWidth < 1024;
  isModernBrowser = "visibilityState" in document;
  init();
  window.addEventListener('resize', init);
}
function init() {
  isMobile = window.innerWidth < 1024; // Update isMobile on resize
  if (isMobile) {
    navigationMobile.init();
    searchMobile.init();
  }
  iframeTitle.init();
  navigationDesktop.init();
  accordions2023.init();
  tabs2023.init();
  statsAnimated.init();
  promoBlockPopUp.init();
  banner__hp__2023.init();
  imageTransition.init();
  smoothScroll.init();
  scrollToTargetWithOffset.init();
  skipToMainContent.init();
  ar2023.init();

}

var ar2023 = {
  init: function(){

    setTimeout(() => {
      const arArrow = document.querySelector('.banner__ar__2023 .arrow_floating');
      const imgTopAr = document.querySelector(".banner__ar__2023 .image-top");
      if (arArrow){
        arArrow.classList.add('new-class');
      }
      if (imgTopAr){
        imgTopAr.classList.add("new-class");
      }
    }, 500);
         function showScrollingDiv() {
          const arNavFixed2023 = document.querySelector(".ar__2023__nav");
          const arBanner2023 = document.querySelector('.banner__ar__2023, .banner__wrapper__2023');
        
          const bannerBottom = arBanner2023.offsetTop + arBanner2023.offsetHeight;
          const scrollPosition = window.scrollY;
        
          // Instantly reserve space for the nav by adjusting the top margin of the body
          const navHeight = arNavFixed2023.offsetHeight; // Calculate nav height outside of the if-statement for efficiency
        
          if (scrollPosition >= bannerBottom) {
            document.body.style.paddingTop = `${navHeight}px`; // Instant space reservation
            arNavFixed2023.classList.add('fixed-nav');
          } else {
            document.body.style.paddingTop = 0; // Remove space reservation when not needed
            arNavFixed2023.classList.remove('fixed-nav');
          }
        }
        
        window.addEventListener('scroll', showScrollingDiv);

  },
}


var skipToMainContent = {
  init: function(){
    const skipLink = document.querySelector('.skip-link');
    if(skipLink){

      skipLink.addEventListener('click', function(event) {
        event.preventDefault();
        let targetId = this.getAttribute('href').substring(1);
        let targetElement = document.getElementById(targetId);
        if (targetElement) {
            targetElement.tabIndex = -1;
            targetElement.focus();
        }
      });
    }

  },
}

var iframeTitle = {
  init: function(){
  function getVimeoVideoInfo(videoId, accessToken, iframe) {
    // Vimeo API endpoint for video information
    var apiUrl = `https://api.vimeo.com/videos/${videoId}`;
    // Make a fetch request to the Vimeo API
    fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    })
    .then(response => response.json())
    .then(data => {
      // Extract the video title from the API response
      var videoTitle = data.name;
      iframe.title = videoTitle;
      //console.log("Video title for Vimeo video: " + videoTitle);
    })
    .catch(error => console.error('Error fetching video details from Vimeo:', error));
  }
    // Loop through each iframe using for...of
    var videoFrames = document.getElementsByClassName('video-frame');
    for (const iframe of videoFrames) {
      // Extract the Vimeo video ID from the data-vimeo-id attribute
      var vimeoVideoId = iframe.getAttribute('data-vimeo-id');
      // Call the function to get video information from Vimeo
      getVimeoVideoInfo(vimeoVideoId, 'e0ada91619642d6fcf1351d9c82c9d02', iframe);
      //console.log("Vimeo id: " + vimeoVideoId);
    }
  },
}

var searchMobile = {
  init: function(){
    const search = document.querySelector(".icon__search");
      search.addEventListener("click", function () {
        search.classList.toggle("is-open");
        let expanded = search.getAttribute('aria-expanded') === 'true' || false;
        search.setAttribute('aria-expanded', !expanded);
        document.querySelector(".search__navigation").hidden = expanded;
        document.querySelector(".search__navigation").classList.toggle("is-open");
    });
  },
};


var navigationMobile = {
  init: function () {
      const pageHeader = document.querySelector(".main__navigation");
      const toggleMenu = pageHeader.querySelector(".mobile__menu__button");
      const menuWrapper = pageHeader.querySelector(".menu-wrapper");
      const level1Links = pageHeader.querySelectorAll(".level-1 > li > button");
      const listWrapper2 = pageHeader.querySelector(
        ".list-wrapper:nth-child(2)"
      );
      const listWrapper3 = pageHeader.querySelector(
        ".list-wrapper:nth-child(3)"
      );
      const listWrapper4 = pageHeader.querySelector(
        ".list-wrapper:nth-child(4)"
      );
      const subMenuWrapper2 = listWrapper2.querySelector(".sub-menu-wrapper");
      const subMenuWrapper3 = listWrapper3.querySelector(".sub-menu-wrapper");
      const subMenuWrapper4 = listWrapper4.querySelector(".sub-menu-wrapper");
      const backOneLevelBtns = pageHeader.querySelectorAll(".back-one-level");
      const isVisibleClass = "is-visible";
      const isActiveClass = "is-active";
      const search = document.querySelector(".icon__search");

      search.addEventListener("click", function () {
        search.classList.toggle("is-open");
        document
          .querySelector(".search__navigation")
          .classList.toggle("is-open");
        if (search.className.includes("is-open")) {
          menuWrapper.classList.remove(isVisibleClass);
          toggleMenu.classList.remove(isVisibleClass);
          toggleMenu.innerText = "Menu";
        }
      });

      toggleMenu.addEventListener("click", function () {
        menuWrapper.classList.toggle(isVisibleClass);
        search.classList.remove("is-open");
        document
          .querySelector(".search__navigation")
          .classList.remove("is-open");
        if (!this.classList.contains(isVisibleClass)) {
          toggleMenu.innerText = "Close";
          toggleMenu.classList.add(isVisibleClass);
          listWrapper2.classList.remove(isVisibleClass);
          listWrapper3.classList.remove(isVisibleClass);
          listWrapper4.classList.remove(isVisibleClass);
          const menuLinks = menuWrapper.querySelectorAll("button");
          for (const menuLink of menuLinks) {
            menuLink.classList.remove(isActiveClass);
          }
        } else {
          toggleMenu.innerText = "Menu";
          toggleMenu.classList.remove(isVisibleClass);
        }
      });

      for (const level1Link of level1Links) {
        level1Link.addEventListener("click", function (e) {
          const siblingList = level1Link.nextElementSibling;
          if (siblingList) {
            e.preventDefault();
            this.classList.add(isActiveClass);
            const cloneSiblingList = siblingList.cloneNode(true);
            subMenuWrapper2.innerHTML = "";
            subMenuWrapper2.append(cloneSiblingList);
            listWrapper2.classList.add(isVisibleClass);
          }
        });
      }

      listWrapper2.addEventListener("click", function (e) {
        const target = e.target;
        if (
          target.tagName.toLowerCase() === "button" &&
          target.nextElementSibling
        ) {
          const siblingList = target.nextElementSibling;
          e.preventDefault();
          target.classList.add(isActiveClass);
          const cloneSiblingList = siblingList.cloneNode(true);
          subMenuWrapper3.innerHTML = "";
          subMenuWrapper3.append(cloneSiblingList);
          listWrapper3.classList.add(isVisibleClass);
        }
      });
      listWrapper3.addEventListener("click", function (e) {
        const target = e.target;
        if (
          target.tagName.toLowerCase() === "button" &&
          target.nextElementSibling
        ) {
          const siblingList = target.nextElementSibling;
          e.preventDefault();
          target.classList.add(isActiveClass);
          const cloneSiblingList = siblingList.cloneNode(true);
          subMenuWrapper4.innerHTML = "";
          subMenuWrapper4.append(cloneSiblingList);
          listWrapper4.classList.add(isVisibleClass);
        }
      });

      for (const backOneLevelBtn of backOneLevelBtns) {
        backOneLevelBtn.addEventListener("click", function () {
          const parent = this.closest(".list-wrapper");
          parent.classList.remove(isVisibleClass);
          parent.previousElementSibling
            .querySelector(".is-active")
            .classList.remove(isActiveClass);
        });
      }
    
  },
};


var navigationDesktop = {
  init: function () {
      const openMenu = document.querySelectorAll(".main__menu__item__holder__nested");
      const mainMenuNav = document.querySelector(".main__menu")
      const closeMenu = document.querySelectorAll(".close__nav");
      const closeMenuBottom = document.querySelectorAll(".close__nav__bottom");
      const search = document.querySelector(".icon__search");
      const closeSearch = document.querySelector(".close__search_2023");
      const openLeveltwo = document.querySelectorAll(".has_sub_menu_level_two");
      const openLevelthree = document.querySelectorAll(".has_sub_menu_level_three");
      const sub__menu__main = document.querySelectorAll(".sub__menu__main");
      navCards = false;

      for (const [elemIndex, elem] of openMenu.entries()) {
        elem.addEventListener("click", function () {

          for (const element of openMenu) { 

            if (element === elem && !element.classList.contains("is__active")) {
              element.classList.add("is__active");
              mainMenuNav.classList.add("is__active");
              document.querySelector(".search__navigation").classList.remove("is-open");
              element.parentElement.querySelector(".close__nav").classList.remove("is__active");
              search.classList.remove("is-open");
              document.querySelectorAll('.menu__nav__cards').forEach(menu__nav__cards => {
                if(navCards){
                  menu__nav__cards.classList.remove("not__active");
                }
             });
              for (const item of element.parentElement.querySelectorAll(".sub__menu__main .is__active")) {
                item.classList.remove("is__active");
              }
            } else if (element === elem && element.classList.contains("is__active")) {
              mainMenuNav.classList.remove("is__active");
              element.classList.remove("is__active");
            }
            else {
              element.classList.remove("is__active");
              mainMenuNav.classList.remove("is__active");
            }
          }

          for (const [index, megaMenu] of sub__menu__main.entries()) {
            if (index == elemIndex && !megaMenu.classList.contains("is__active")) {
              megaMenu.classList.add("is__active");
              mainMenuNav.classList.add("is__active");
            } else {
              megaMenu.classList.remove("is__active");
            }
          }
        });
      }

      // level two
      for (const [levelTwoIndex, levelTwo] of openLeveltwo.entries()) {
     const menu__nav__cards = levelTwo.closest('.main__menu__item').querySelector('.menu__nav__cards')

        levelTwo.querySelector("button").addEventListener("click", function () {
          for (const [levelTwoIndexItem, levelTwoItem] of document.querySelectorAll(".sub__menu__level_two").entries()) {
            if (levelTwoIndexItem == levelTwoIndex && !levelTwoItem.classList.contains("is__active")) {
              levelTwoItem.classList.add("is__active");
              //menu__nav__cards.style.visibility = "hidden";
              if(navCards){
                 menu__nav__cards.classList.add("not__active");
              }
            }
            else if (levelTwoIndexItem == levelTwoIndex && levelTwoItem.classList.contains("is__active")){
              //menu__nav__cards.style.visibility = "visible";
              if(navCards){
                menu__nav__cards.classList.remove("not__active");
              }
              levelTwoItem.classList.remove("is__active");
              for (const [levelThreeIndexItem, levelThreeItem] of document.querySelectorAll(".sub__menu__level_three").entries()) {
                levelThreeItem.classList.remove("is__active");
              }
            }  
            else {
              levelTwoItem.classList.remove("is__active");
              for (const [levelThreeIndexItem, levelThreeItem] of document.querySelectorAll(".sub__menu__level_three").entries()) {
                levelThreeItem.classList.remove("is__active");
              }
            }
          }
        });
      }

      //level three
      for (const [levelThreeIndex, levelThree] of openLevelthree.entries()) {
        
        levelThree.addEventListener("click", function () {
          for (const [levelThreeIndexItem, levelThreeItem] of document
            .querySelectorAll(".sub__menu__level_three")
            .entries()) {
            if (
              levelThreeIndexItem == levelThreeIndex &&
              !levelThreeItem.classList.contains("is__active")
            ) {
              levelThreeItem.classList.add("is__active");
            } else {
              levelThreeItem.classList.remove("is__active");
            }
          }
        });
      }

      // close menu button
      for (const closeButton of closeMenu) {
        const menu__nav__cards = closeButton.closest('.main__menu__item').querySelector('.menu__nav__cards')

        closeButton.addEventListener("click", function () {
          closeButton.classList.toggle("is__active");
          if (closeButton.className.includes("is__active")) { 
            mainMenuNav.classList.remove("is__active");
            //menu__nav__cards.style.visibility = "visible";

            if(menu__nav__cards != null){
              menu__nav__cards.classList.remove("not__active");
            }

            closeButton.closest(".sub__menu__main").classList.remove("is__active");
            closeButton.closest(".main__menu__item").querySelector(".main__menu__item__holder").classList.remove("is__active");
          }
        });
      }

      // close bottom menu button
      for (const closeButton of closeMenuBottom) {
        closeButton.addEventListener("click", function () {
          closeButton.classList.toggle("is__active");
          if (closeButton.className.includes("is__active")) {
            mainMenuNav.classList.remove("is__active");

            closeButton.closest(".sub__menu__main").classList.remove("is__active");
            closeButton.closest(".main__menu__item").querySelector(".main__menu__item__holder").classList.remove("is__active");
          }
        });
      }
      closeSearch.addEventListener("click", function () {
        document.querySelector(".search__navigation").classList.toggle("is-open");
        search.classList.toggle("is-open");

      });
      // search button
      search.addEventListener("click", function () {
        search.classList.toggle("is-open");
        let expanded = search.getAttribute('aria-expanded') === 'true' || false;
        search.setAttribute('aria-expanded', !expanded);
        document.querySelector(".search__navigation").hidden = expanded;

        document.querySelector(".search__navigation").classList.toggle("is-open");
        if (search.className.includes("is-open")) {
          mainMenuNav.classList.remove("is__active");

          document.querySelectorAll(".sub__menu__main.is__active").forEach(menu => {
            menu.classList.remove("is__active");
          })
          document.querySelectorAll(".main__menu__item__holder.is__active").forEach(menu => {
            menu.classList.remove("is__active");
          })
         document.querySelectorAll('.menu__nav__cards').forEach(menu__nav__cards => {
            menu__nav__cards.classList.remove("not__active");

         });
        }
      });
  },
};


var scrollToTargetWithOffset = {
  init: function(){
    // Check if the URL contains an anchor (hash)
    if (window.location.hash) {
      // Get the target element by the anchor (hash)
      var targetElement = document.querySelector(window.location.hash); 
      if (targetElement) {
          var offset = 90; // Adjust this value based on the height of your top navigation
          var targetPosition = targetElement.getBoundingClientRect().top + window.scrollY - offset;    
          // Scroll smoothly to the target position
          window.scrollTo({
              top: targetPosition,
              behavior: 'smooth'
          });
      }
   }
  },
};

smoothScroll = {
  init: function(){
// Get all anchor elements with href containing #
const anchorLinks = document.querySelectorAll('a[href*="#"]:not(.content-modal-trigger):not(.close_promo_block a):not(.content__tabs_2023_link)');

// Iterate through each anchor link and add a click event listener
anchorLinks.forEach(function (anchorLink) {
  anchorLink.addEventListener('click', function (event) {
    // Prevent the default behavior of the anchor link
    event.preventDefault();

    // Get the target element's ID from the href attribute
    const targetId = this.hash.substring(1);

    // Find the target element by its ID
    const targetElement = document.getElementById(targetId);
    console.log(targetId);
    if (targetElement) {
      // Calculate the scroll position by subtracting 160 pixels
      const scrollPosition = targetElement.offsetTop - 90;

      // Smooth scroll to the target element
      window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth'
      });
      }
    });
  });

},
};




imageTransition = {
init: function(){
  window.onscroll = function(){
    const elem = document.querySelectorAll(".image_colspan_9 .fit-cover");
      for(const element of elem){
        if(element.getBoundingClientRect().top <= 200){
          // for(const other of elem){
          //   other.classList.remove("move-left-active")
          // }
          element.classList.add("move-left-active")
        }
      }
    }
  },
};

 var banner__hp__2023 = {
  init: function () {

  const banner__hp__2023 = document.querySelector(".banner__hp__2023, .banner__wrapper__2023");
      if(banner__hp__2023 != null){
        window.addEventListener("load", () => {
          setTimeout(() => {
              banner__hp__2023.classList.add("is__active");
            }, 500);
        });  
      }
    },
  };

var promoBlockPopUp = {
  init: function () {
    const pop__up__box = document.querySelectorAll(".pop__up__box");
    const close_promo_block = document.querySelector(".close_promo_block p");
    for (const item of pop__up__box){
      item.addEventListener('click', () => {
        const { popupTrigger } = item.dataset
        const popupModal = item.closest(".promo__block__2023").querySelector(`[data-popup-modal="${popupTrigger}"]`)
        popupModal.classList.add('is__active');
        popupModal.querySelector('.close_promo_block p').addEventListener('click', () => {
          popupModal.classList.remove('is__active')
       })
    });
  }




  },

};


var statsAnimated = {
  init: function () {
    // if (document.querySelector('.facts-section').length) {
    //   let a = 0;
    //   document.querySelector(window).scroll(function () {
    //     console.log(oTop);
    //       const oTop = document.querySelector('.facts-section').offset().top - window.innerHeight;
    //       if (a === 0 && document.querySelector(window).scrollTop() > oTop) {
    //           document.querySelector('.counter-value-no-decimal').forEach(function () {
    //               let $this = document.querySelector(this);
    //               this({ Counter: 0 }).animate({ Counter: $this.textContent }, {
    //                   duration: 2000,
    //                   easing: 'swing',
    //                   step: function () {
    //                       $this.textContent=this.Counter.toFixed(0);
    //                   }
    //               });
    //           });
    //           document.querySelector('.counter-value').forEach(function () {
    //               let $this = document.querySelector(this);
    //               this({ Counter: 0 }).animate({ Counter: $this.textContent }, {
    //                   duration: 2000,
    //                   easing: 'swing',
    //                   step: function () {
    //                       $this.textContent=this.Counter.toFixed(2);
    //                   }
    //               });
    //           });
    //           a = 1;
    //       }
    //   });
    // };
  },
};
var tabs2023 = {
  init: function () {
    const tabContainers = document.querySelectorAll(".tab-container");
    tabContainers.forEach((tabContainer, i) => {
        const tabs = tabContainer.querySelectorAll(".tab");
        const tabContent = tabContainer.querySelectorAll(".tab-content");
        tabs.forEach((tab, i) => {
          tab.addEventListener("click", function () {
              tabs.forEach(tab => tab.classList.remove("active"));
              this.classList.add("active");
              tabContent.forEach(content => content.classList.add("hidden"));
              tabContent[i].classList.remove("hidden");
          });
        });
    })
  },
};

var accordions2023 = {
  init: function () {
  
// Function to handle opening an accordion
const openAccordion = (accordion) => {
  const content = accordion.querySelector(".accordion__content");
  accordion.classList.add("accordion__active");
  content.style.maxHeight = content.scrollHeight + "px";
  accordion.setAttribute("aria-expanded", "true");
  // Set elementPosition to false to prevent scrolling
  const elementPosition = false;

  if (elementPosition !== false) {
    const scrollToPosition =
      accordion.getBoundingClientRect().top -
      document.body.getBoundingClientRect().top -
      150;
    window.scrollTo({ top: scrollToPosition, behavior: "smooth" });
  }

  // Ensure a smooth transition by setting transition timing consistently
  setTimeout(() => {
    content.style.transition = "max-height 0.3s ease"; // Adjust the duration and timing function as needed
  }, 0);
};

// Function to handle closing an accordion
const closeAccordion = (accordion) => {
  const content = accordion.querySelector(".accordion__content");
  content.style.transition = "max-height 0.3s ease"; // Adjust the duration and timing function as needed
  accordion.classList.remove("accordion__active");
  content.style.maxHeight = null;
  accordion.setAttribute("aria-expanded", "false");
};

// Function to check if any accordion is open within a wrapper
const isAnyAccordionOpen = (wrapper) => {
  const accordionsInWrapper = wrapper.querySelectorAll(".accordion_2023");
  return Array.from(accordionsInWrapper).some((accordion) =>
    accordion.classList.contains("accordion__active")
  );
};

// Get all buttons with the common class
const accordionButtons = document.querySelectorAll(".accordion_buttons_open_collpase");

// Function to handle opening all accordions within a wrapper
const handleOpenAll = (wrapperId) => {
  const wrapper = document.getElementById(wrapperId);
  const accordionsInWrapper = wrapper.querySelectorAll(".accordion_2023");
  accordionsInWrapper.forEach((accordion) => openAccordion(accordion));
  toggleButtonsVisibility(wrapper);
};

// Function to handle closing all accordions within a wrapper
const handleCloseAll = (wrapperId) => {
  const wrapper = document.getElementById(wrapperId);
  const accordionsInWrapper = wrapper.querySelectorAll(".accordion_2023");
  accordionsInWrapper.forEach((accordion) => closeAccordion(accordion));
  toggleButtonsVisibility(wrapper);
};

// Function to toggle visibility of buttons based on accordion state within a wrapper
const toggleButtonsVisibility = (wrapper) => {
  const expandAllButton = wrapper.querySelector(".accordion_buttons_open_collpase[data-action='expand']");
  const collapseAllButton = wrapper.querySelector(".accordion_buttons_open_collpase[data-action='collapse']");

  if (expandAllButton && collapseAllButton) {
    const anyAccordionOpen = isAnyAccordionOpen(wrapper);

    expandAllButton.style.display = anyAccordionOpen ? "none" : "block";
    collapseAllButton.style.display = anyAccordionOpen ? "block" : "none";
  }
};

// Initially, hide the "Collapse All" button if all accordions are initially collapsed
document.querySelectorAll(".accordions_wrapper_2023").forEach((wrapper) => {
  const collapseAllButton = wrapper.querySelector(".accordion_buttons_open_collpase[data-action='collapse']");
  if (collapseAllButton) {
    collapseAllButton.style.display = isAnyAccordionOpen(wrapper) ? "block" : "none";
  }
});

// Attach click event handlers to all buttons
accordionButtons.forEach((button) => {
  button.onclick = () => {
    const wrapper = button.closest(".accordions_wrapper_2023");
    const action = button.getAttribute("data-action");

    if (action === "expand") {
      handleOpenAll(wrapper.id);
    } else if (action === "collapse") {
      handleCloseAll(wrapper.id);
    }
  };
});

// Attach click event handlers to individual accordions
document.querySelectorAll(".accordion_2023").forEach((accordion) => {
  const intro = accordion.querySelector(".accordion__intro");
  const content = accordion.querySelector(".accordion__content");

  intro.onclick = () => {

    const wrapper = accordion.closest(".accordions_wrapper_2023");

    if (content.style.maxHeight) {
      closeAccordion(accordion);
    } else {
      if (!accordion.classList.contains("open_close_one_by_one")) {
        const accordionsInWrapper = wrapper.querySelectorAll(".accordion_2023");
        accordionsInWrapper.forEach((acc) => closeAccordion(acc));
      }
      openAccordion(accordion);
    }

    // Delay toggling buttons visibility to ensure the correct state
    setTimeout(() => toggleButtonsVisibility(wrapper), 0);
  };
});

  // Initially, toggle the visibility of buttons based on the accordion state for each wrapper
  document.querySelectorAll(".accordions_wrapper_2023").forEach((wrapper) => {
    toggleButtonsVisibility(wrapper);
  });

  },
};


